@font-face {
  font-family: "Gilroy-Medium" !important;
  font-weight: 100;
  src: url("../src/assets/styles/Gilroy-Medium.ttf") format("truetype");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loaderWindow {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0px;
  z-index: 1000;
  background: #e5e5e5;
  opacity: 0.8;
  overflow-x: hidden;
}

.loaderSpinner {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 25%;
  top: 50%;
  z-index: 1000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
  /*

iframe {
 
   display: none

    z-index: -1 !important; 
}
  */

@import "component/Header/style.css";
@import "assets/styles/main.scss";
@import "assets/styles/bootstrap.css";
@import "assets/styles/bootstrap-grid.css";
